import React  from 'react'
import { Container, FormGroup, Label, Col, Input } from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import { qualificationType } from '../shared/data-leads'
import DateTime from 'react-datetime';
import Moment from 'moment'
export type PartnerEmployementDetailProps = {
    formData: qualificationType
    setFormData: (formdata: qualificationType) => any
}
export function PartnerEmployementDetail(props: PartnerEmployementDetailProps) {
    const qualification = props.formData
    return (
        <Container>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4"> Occupation </Label>
                <Col xs="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        onChange={(x) => {
                            const val = x.target.value
                            qualification.PartnerOccupation = val
                            props.setFormData(qualification)
                            
                        }}
                        value={qualification.PartnerOccupation!}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4"> Employer </Label>
                <Col xs="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        onChange={(x) => {
                            const val = x.target.value
                            qualification.PartnerEmployer = val
                            props.setFormData(qualification)
                            
                        }}
                        value={qualification.PartnerEmployer!}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4"> Mode of Employment  </Label>
                <Col xs="8">
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Part time"
                                name="ModeofEmployment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Mode of Employment"] = 'Part time'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Mode of Employment"] === 'Part time'}
                            ></Input>
                            Part time  <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Casual"
                                name="ModeofEmployment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Mode of Employment"] = 'Casual'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Mode of Employment"] === 'Casual'}
                            ></Input>
                            Casual  <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="full time"
                                name="ModeofEmployment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Mode of Employment"] = 'Full Time'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Mode of Employment"] === 'Full Time'}
                            ></Input>
                            Full time <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input 
                                name="ModeofEmployment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Mode of Employment"] = 'Contractor'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Mode of Employment"] === 'Contractor'}
                            ></Input>
                            Contractor <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input 
                                name="ModeofEmployment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Mode of Employment"] = 'Self Empoyed'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Mode of Employment"] === 'Self Empoyed'}
                            ></Input>
                            Self Empoyed <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="6"> Length of Employment </Label>
                <Col xs="6">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        onChange={(x) => {
                            const val = x.target.value
                            qualification["Partner Length of Employment"] = val
                            props.setFormData(qualification)
                            
                        }}
                        value={qualification["Partner Length of Employment"]}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="6"> On a probationary period </Label>
                <Col xs="6">
                    <Switch onChange={(x) => { 
                                if(!qualification.Purchase) {
                                    qualification.Purchase = {}
                                } 
                                qualification["Partner On a probationary period"] = x.value() ? "YES" : 'NO'
                                props.setFormData(qualification)} 
                            }
                            value={qualification["Partner On a probationary period"]  === 'YES' ? true : false } onText={"YES"} offText={"NO"} defaultValue={true}  onColor="warning" />
                </Col>
            </FormGroup>
            <FormGroup className="row" style={{display : qualification["On a probationary period"] === "YES" ? '' : 'none'}}>
                <Label htmlFor="staticEmail" xs="5"> When does it finish? </Label>
                <Col xs="7">
                <DateTime
                        onChange={(val: any) => {
                            qualification["Partner When does it finish"] = Moment(val).format("L") 
                            props.setFormData(qualification)
                        }}
                        timeFormat={false}
                        value={Moment(qualification["Partner When does it finish"])}
                        closeOnSelect={true}
                        inputProps={{ placeholder: "Date" }} />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4"> Wages Payment?  </Label>
                <Col xs="8">
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Weekly"
                                name="WagesPeyment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Wages Payment"] = 'Weekly'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Wages Payment"] === 'Weekly'}
                            ></Input>
                            Weekly   <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Fortnightly"
                                name="WagesPeyment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Wages Payment"] = 'Fortnightly'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Wages Payment"] === 'Fortnightly'}
                            ></Input>
                            Fortnightly   <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Monthly "
                                name="WagesPeyment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Wages Payment"] = 'Monthly'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Wages Payment"] === 'Monthly'}
                            ></Input>
                           Monthly <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Annual Salary"
                                name="WagesPeyment"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Partner Wages Payment"] = 'Annual Salary'
                                    props.setFormData(qualification)
                                    
                                }}
                                checked={qualification["Partner Wages Payment"] === 'Annual Salary'}
                            ></Input>
                            Annual Salary <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="6"> Annual Income </Label>
                <Col xs="6">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        onChange={(x) => {
                            const val = x.target.value
                            qualification["Partner Annual Income"] = val
                            props.setFormData(qualification)
                            
                        }}
                        value={qualification["Partner Annual Income"]}
                    ></input>
                </Col>
            </FormGroup>
        </Container>
    )
}