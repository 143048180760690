import { qualificationType } from "../shared/data-leads"

export const FunctionPartnerEmployeeDetail = (leads: qualificationType) => {
    let error = 0
    let errorMessage = ""

    if(leads.PartnerOccupation && leads.PartnerOccupation.length > 0){
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Enter Occupation"
        error = 1
    }

    if(leads.PartnerEmployer && leads.PartnerEmployer.length > 0){
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Enter Employer"
        error = 1
    }

    if(leads["Partner Mode of Employment"] && leads['Partner Mode of Employment'].length > 0){
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Select Mode of Employment"
        error = 1
    }
    if(leads["Partner Length of Employment"] && leads['Partner Length of Employment'].length > 0){
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Enter Length of Employment"
        error = 1
    }
    
    if(leads["Partner Wages Payment"] && leads["Partner Wages Payment"].length > 0) {
        error = 0
    } else {
        
    }
    
    return {
        error: error,
        errorMessage: errorMessage
    }
}