import React from 'react'
import { Container, FormGroup, Label, Input, Col, Row } from 'reactstrap'
import { qualificationType } from '../shared/data-leads'
import Switch from 'react-bootstrap-switch'
import DateTime from 'react-datetime';
import Moment from 'moment'
import Select from "react-select";

export type OtherIncomeProps = {
    formData: qualificationType
    setFormData: (formdata: qualificationType) => any
}
export function OtherIncome(props: OtherIncomeProps) {
    const qualification = props.formData

    return (
        <Container>
            <FormGroup check>
                <Label check>
                    <Input type="checkbox" onChange={() => {
                        if (qualification["Other Income"] && qualification["Other Income"].indexOf("Rental Income") > -1) {
                            const idx = qualification["Other Income"].indexOf("Rental Income")
                            qualification["Other Income"].splice(idx, 1)
                        } else {
                            if (!qualification["Other Income"]) {
                                qualification["Other Income"] = []
                            }
                            qualification["Other Income"].push("Rental Income")
                        }
                        props.setFormData(qualification)

                    }}
                        checked={qualification["Other Income"] && qualification["Other Income"].indexOf("Rental Income") > -1} ></Input>
                    Rental Income
                    <span className="form-check-sign">
                        <span className="check"></span>
                    </span>
                </Label>
            </FormGroup>
            <Row style={{ display: qualification["Other Income"] && qualification["Other Income"].indexOf("Rental Income") > -1 ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Annual Amount </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    qualification["Rental Income"] = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification["Rental Income"]}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup check >
                <Label check>
                    <Input onChange={() => {
                        if (qualification["Other Income"] && qualification["Other Income"].indexOf("Centrelink") > -1) {
                            const idx = qualification["Other Income"].indexOf("Centrelink")
                            qualification["Other Income"].splice(idx, 1)
                        } else {
                            if (!qualification["Other Income"]) {
                                qualification["Other Income"] = []
                            }
                            qualification["Other Income"].push("Centrelink")
                        }
                        props.setFormData(qualification)

                    }}
                        checked={qualification["Other Income"] && qualification["Other Income"].indexOf("Centrelink") > -1} type="checkbox"></Input>
                     Centrelink
                    <span className="form-check-sign">
                        <span className="check"></span>
                    </span>
                </Label>
            </FormGroup>
            <Row style={{ display: qualification["Other Income"] && qualification["Other Income"].indexOf("Centrelink") > -1 ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Type </Label>
                        <Col xs="8">
                            <Select
                                className="react-select react-select-primary"
                                classNamePrefix="react-select" 
                                value={ {label: qualification.Centrelink && qualification.Centrelink.Type ? qualification.Centrelink.Type : '',  value: qualification.Centrelink && qualification.Centrelink.Type ? qualification.Centrelink.Type : ''} }
                                onChange={(value: any) => { 
                                    if (!qualification.Centrelink) {
                                        qualification.Centrelink = {}
                                    }
                                    qualification.Centrelink.Type = value.value
                                    props.setFormData(qualification)
                                }}
                                options={[
                                    
                                    { value: "Youth Allowance", label: "Youth Allowance" },
                                    { value: "Part A & B", label: "Part A & B" },
                                    {value: "Pension", label: 'Pension'},
                                    {value: "Rent Assistance", label: 'Rent Assistance'},
                                    {value: "Carers Pension ", label: 'Carers Pension '},
                                    {value: "Disability Pesnion ", label: 'Disability Pesnion '},
                                ]}
                                placeholder="Single Select"
                            />
                             
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Annual Amount </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification.Centrelink) {
                                        qualification.Centrelink = {}
                                    }
                                    qualification.Centrelink.Amount = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification.Centrelink && qualification.Centrelink.Amount ? qualification.Centrelink.Amount : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup check >
                <Label check>
                    <Input onChange={() => {
                        if (qualification["Other Income"] && qualification["Other Income"].indexOf("Second Job") > -1) {
                            const idx = qualification["Other Income"].indexOf("Second Job")
                            qualification["Other Income"].splice(idx, 1)
                        } else {
                            if (!qualification["Other Income"]) {
                                qualification["Other Income"] = []
                            }
                            qualification["Other Income"].push("Second Job")
                        }
                        props.setFormData(qualification)

                    }}
                        checked={qualification["Other Income"] && qualification["Other Income"].indexOf("Second Job") > -1} type="checkbox"></Input>
                    Second Job
                    <span className="form-check-sign">
                        <span className="check"></span>
                    </span>
                </Label>
            </FormGroup>
            <Row style={{ display: qualification["Other Income"] && qualification["Other Income"].indexOf("Second Job") > -1 ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Occupation </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification["Second Job"]) {
                                        qualification['Second Job'] = {}
                                    }
                                    qualification['Second Job'].Occupation = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification['Second Job'] && qualification['Second Job'].Occupation ? qualification['Second Job'].Occupation : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Employer </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification["Second Job"]) {
                                        qualification['Second Job'] = {}
                                    }
                                    qualification['Second Job'].Employer = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification['Second Job'] && qualification['Second Job'].Employer ? qualification['Second Job'].Employer : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Mode of Employment  </Label>
                        <Col xs="8">
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="Part time"
                                        name="ModeofEmployment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Mode of Employment"] = 'Part time'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification['Second Job'] && qualification['Second Job']["Mode of Employment"] === 'Part time'}
                                    ></Input>
                            Part time  <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="Casual"
                                        name="ModeofEmployment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Mode of Employment"] = 'Casual'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification["Second Job"] && qualification["Second Job"]["Mode of Employment"] === 'Casual'}
                                    ></Input>
                            Casual  <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="full time"
                                        name="ModeofEmployment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Mode of Employment"] = 'Full Time'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification['Second Job'] && qualification['Second Job']["Mode of Employment"] === 'Full Time'}
                                    ></Input>
                            Full time <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        name="ModeofEmployment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Mode of Employment"] = 'Contractor'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification['Second Job'] && qualification['Second Job']["Mode of Employment"] === 'Contractor'}
                                    ></Input>
                            Contractor <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        name="ModeofEmployment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Mode of Employment"] = 'Self Empoyed'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification['Second Job'] && qualification['Second Job']["Mode of Employment"] === 'Self Empoyed'}
                                    ></Input>
                            Self Empoyed <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="6"> Length of Employment </Label>
                        <Col xs="6">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification["Second Job"]) {
                                        qualification['Second Job'] = {}
                                    }
                                    qualification['Second Job']["Length of Employment"] = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification["Second Job"] && qualification["Second Job"]["Length of Employment"]}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="6"> On a probationary period </Label>
                        <Col xs="6">
                            <Switch onChange={(x) => {
                                if (!qualification["Second Job"]) {
                                    qualification["Second Job"] = {}
                                }
                                qualification["Second Job"]["On a probationary period"] = x.value() ? "YES" : 'NO'
                                props.setFormData(qualification)
                            }
                            }
                                value={qualification["Second Job"] && qualification["Second Job"]["On a probationary period"] === 'YES' ? true : false} onText={"YES"} offText={"NO"} defaultValue={true} onColor="warning" />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row" style={{ display: qualification["On a probationary period"] === "YES" ? '' : 'none' }}>
                        <Label htmlFor="staticEmail" xs="5"> When does it finish? </Label>
                        <Col xs="7">
                            <DateTime
                                onChange={(val: any) => {
                                    if (!qualification["Second Job"]) {
                                        qualification['Second Job'] = {}
                                    }
                                    qualification['Second Job']["When does it finish"] = Moment(val).format("L")
                                    props.setFormData(qualification)
                                }}
                                timeFormat={false}
                                value={Moment(qualification["Second Job"] && qualification["Second Job"]["When does it finish"] ? qualification["Second Job"]["When does it finish"] : '')}
                                closeOnSelect={true}
                                inputProps={{ placeholder: "Date" }} />
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Wages Payment?  </Label>
                        <Col xs="8">
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="Weekly"
                                        name="WagesPeyment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Wages Payment"] = 'Weekly'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification["Second Job"] && qualification["Second Job"]["Wages Payment"] === 'Weekly'}
                                    ></Input> Weekly   <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="Fortnightly"
                                        name="WagesPeyment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Wages Payment"] = 'Fortnightly'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification['Second Job'] && qualification['Second Job']["Wages Payment"] === 'Fortnightly'}
                                    ></Input>
                            Fortnightly   <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="Monthly "
                                        name="WagesPeyment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Wages Payment"] = 'Monthly'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification["Second Job"] && qualification["Second Job"]["Wages Payment"] === 'Monthly'}
                                    ></Input>
                           Monthly <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                            <FormGroup check className="form-check-radio" >
                                <Label check>
                                    <Input
                                        defaultValue="Annual Salary"
                                        name="WagesPeyment"
                                        type="radio"
                                        onChange={(x) => {
                                            if (!qualification["Second Job"]) {
                                                qualification['Second Job'] = {}
                                            }
                                            qualification['Second Job']["Wages Payment"] = 'Annual Salary'
                                            props.setFormData(qualification)

                                        }}
                                        checked={qualification["Second Job"] && qualification["Second Job"]["Wages Payment"] === 'Annual Salary'}
                                    ></Input>
                            Annual Salary <span className="form-check-sign"></span>

                                </Label>
                            </FormGroup>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="6"> Average Annual Other Income</Label>
                        <Col xs="6">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification["Second Job"]) {
                                        qualification['Second Job'] = {}
                                    }
                                    qualification["Second Job"]["Average Annual Other Income"] = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification["Second Job"] && qualification["Second Job"]["Average Annual Other Income"] ? qualification["Second Job"]["Average Annual Other Income"] : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <FormGroup check >
                <Label check>
                    <Input onChange={() => {
                        if (qualification["Other Income"] && qualification["Other Income"].indexOf("Other") > -1) {
                            const idx = qualification["Other Income"].indexOf("Other")
                            qualification["Other Income"].splice(idx, 1)
                        } else {
                            if (!qualification["Other Income"]) {
                                qualification["Other Income"] = []
                            }
                            qualification["Other Income"].push("Other")
                        }
                        props.setFormData(qualification)

                    }}
                        checked={qualification["Other Income"] && qualification["Other Income"].indexOf("Other") > -1} type="checkbox"></Input>
                    Other
                    <span className="form-check-sign">
                        <span className="check"></span>
                    </span>
                </Label>
            </FormGroup>
            <Row style={{ display: qualification["Other Income"] && qualification["Other Income"].indexOf("Other") > -1 ? '' : 'none' }}>
                <Col xs={1}></Col>
                <Col xs={11}>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Type </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification["Other"]) {
                                        qualification['Other'] = {}
                                    }
                                    qualification['Other'].Type = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification['Other'] && qualification['Other'].Type ? qualification['Other'].Type : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                    <FormGroup className="row">
                        <Label htmlFor="staticEmail" xs="4"> Annual Amount </Label>
                        <Col xs="8">
                            <input
                                className="form-control-plaintext"
                                type="text"
                                onChange={(x) => {
                                    const val = x.target.value
                                    if (!qualification["Other"]) {
                                        qualification['Other'] = {}
                                    }
                                    qualification['Other'].Amount = val
                                    props.setFormData(qualification)

                                }}
                                value={qualification['Other'] && qualification['Other'].Amount ? qualification['Other'].Amount : ''}
                            ></input>
                        </Col>
                    </FormGroup>
                </Col>
            </Row>
            <p>If no additional income, please skip this step</p>
        </Container>
    )
}