import { qualificationType } from "../shared/data-leads"

export const homeFunction = (leads: qualificationType) => {
    let error = 0
    let errorMessage = ""
    if (leads.Reasons && leads.Reasons.length > 0) {
        if (leads.Reasons === "Refinance") {
            if (leads["Refinance"] && leads["Refinance"].Bank && leads["Refinance"].Bank.length > 0) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Bank Name"
                error = 1
            }
            if (leads["Refinance"] && leads["Refinance"]["Amount Owing"] && leads["Refinance"]!["Amount Owing"].length > 0) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Amount Owing"
                error = 1
            }
            if (leads["Refinance"] && leads["Refinance"]["Value"] && leads["Refinance"]["Value"].length > 0) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Value"
                error = 1
            }
            if (leads["Refinance"] && leads["Refinance"]["Interest Rate"] && leads["Refinance"]["Interest Rate"].length > 0) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Interest Rate"
                error = 1
            }
        } else if (leads.Reasons === "Refinance - Debt Consolidation") {
            if (leads["Refinance - Debt Consolidation"] && leads["Refinance - Debt Consolidation"].length > 0) {
                error = 0
            } else {
                error = 1
                errorMessage = "Please type value of debts to be refinanced"
            }
        } else if (leads.Reasons === "Purchase") {
            if (leads.Purchase && leads.Purchase["Have you found a property"] && leads.Purchase["Have you found a property"] === 'NO') {
                if (leads.Purchase && leads.Purchase["What suburb are you looking"] && leads.Purchase["What suburb are you looking"].length > 0) {
                    error = 0
                } else {
                    error = 1
                    errorMessage = "Please type what suburb are you looking"
                }
            } else {
                error = 0
            }
        } else if (leads.Reasons === "Personal Loan") {
            if (leads["Personal Loan"] && leads["Personal Loan"] && leads["Personal Loan"]["Purpose of loan"] && leads["Personal Loan"]["Purpose of loan"].length > 0) {
                error = 0
            } else {
                error = 1
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please select purpose of loan"
                error = 1
            }
            if (leads["Personal Loan"] && leads["Personal Loan"] && leads["Personal Loan"]["Loan Amount required"] && leads["Personal Loan"]["Loan Amount required"].length > 0) {
                error = 0
            } else {
                error = 1
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please type loan amount required"
                error = 1
            }
        }
    } else {
        error = 1
        errorMessage = "Please Select Reasons for Seeking Service"
    }

    return {
        error: error,
        errorMessage: errorMessage
    }

}