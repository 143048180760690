import React from 'react'
import { Container, FormGroup, Label, Col, Input, Row } from 'reactstrap'
import { qualificationType } from '../shared/data-leads'
import DateTime from 'react-datetime';
import Moment from 'moment'
export type PersonalDetailProps = {
    formData: qualificationType
    setFormData: (formdata: qualificationType) => any
}
export function PersonalDetail(props: PersonalDetailProps) {
    const qualification = props.formData
    return (
        <Container>
            <FormGroup className="row">
                <Label htmlFor="staticName" xs="4"> Full Name </Label>
                <Col xs="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        value={qualification.Name}
                        onChange={(evt) => {
                            const val = evt.target.value
                            qualification.Name = val
                            props.setFormData(qualification)
                        }}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4"> Email </Label>
                <Col xs="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        value={qualification.Email}
                        onChange={(evt) => {
                            const val = evt.target.value
                            qualification.Email = val
                            props.setFormData(qualification)
                        }}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticPhone" xs="4"> Phone Number </Label>
                <Col xs="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        value={qualification["Phone Number"]}
                        onChange={(evt) => {
                            const val = evt.target.value
                            qualification["Phone Number"] = val
                            props.setFormData(qualification)
                        }}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticPostcode" xs="4"> Post Code </Label>
                <Col xs="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        value={qualification["Post Code"]}
                        onChange={(evt) => {
                            const val = evt.target.value
                            qualification["Post Code"] = val
                            props.setFormData(qualification)
                        }}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4">  Date of Birth</Label>
                <Col xs="8">
                    <DateTime
                        onChange={(val: any) => {
                            qualification["Date of Birth"] = Moment(val).format("L")
                            /*
                            const sekarang = Moment(new Date())
                            const selisih = Moment.duration(sekarang.diff(val))
                            console.log("SELISIH", selisih.asDays())*/
                            props.setFormData(qualification)
                        }}
                        timeFormat={false}
                        value={Moment(qualification["Date of Birth"])}
                        closeOnSelect={true}
                        inputProps={{ placeholder: "Date" }} />
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" md="4"> Address</Label>
                <Col md="8">
                    <input
                        className="form-control-plaintext"
                        type="text"
                        value={qualification.Address}
                        onChange={(evt) => {
                            const val = evt.target.value
                            qualification.Address = val
                            props.setFormData(qualification)
                        }}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4">  Residential Status </Label>
                <Col xs="8">
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Renting"
                                name="ResidentialStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Residential Status"] = 'Renting'
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Residential Status"] === 'Renting'}
                            ></Input>
                            Renting  <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Mortgaged "
                                name="ResidentialStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Residential Status"] = 'Mortgaged'
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Residential Status"] === 'Mortgaged'}
                            ></Input>
                            Mortgaged  <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Boarding"
                                name="ResidentialStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Residential Status"] = 'Boarding'
                                    props.setFormData(qualification)
                                }}
                                checked={qualification["Residential Status"] === 'Boarding'}
                            ></Input>
                            Boarding <span className="form-check-sign"></span>

                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="4"> Citizen Status</Label>
                <Col xs="8">
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Australian "
                                name="CitizenStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Citizen Status"] = 'Australian'
                                    props.setFormData(qualification)

                                }}
                                checked={qualification["Citizen Status"] === 'Australian'}
                            ></Input>
                            Australian  <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Visa"
                                name="CitizenStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Citizen Status"] = 'Visa'
                                    props.setFormData(qualification)

                                }}
                                checked={qualification["Citizen Status"] === 'Visa'}
                            ></Input>
                            Visa <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <input
                        className="form-control-plaintext"
                        type="text"
                        placeholder="Type of Visa"
                        style={{ display: qualification["Citizen Status"] === "Visa" ? '' : 'none' }}
                        value={qualification["Type of Visa"]}
                        onChange={(evt) => {
                            const val = evt.target.value
                            qualification["Type of Visa"] = val
                            props.setFormData(qualification)
                        }}
                    ></input>
                </Col>
            </FormGroup>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" md="4"> Marital Status</Label>
                <Col md="8">
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Married/DeFacto "
                                name="MaritalStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Marital Status"] = 'Married/DeFacto'
                                    props.setFormData(qualification)

                                }}
                                checked={qualification["Marital Status"] === 'Married/DeFacto'}
                            ></Input>
                            Married/DeFacto  <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                    <Row style={{ display: qualification["Marital Status"] === 'Married/DeFacto' ? '' : 'none' }}>
                        <Col xs={1}></Col>
                        <Col>
                            <FormGroup className="row">
                                <Label htmlFor="staticEmail" xs="4"> Full Name </Label>
                                <Col xs="8">
                                    <input
                                        className="form-control-plaintext"
                                        type="text"
                                        onChange={(x) => {
                                            const val = x.target.value
                                            qualification["Partner Full Name"] = val
                                            props.setFormData(qualification)
                                        }}
                                        value={qualification["Partner Full Name"]}
                                    ></input>
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label htmlFor="staticEmail" xs="4">  Date of Birth</Label>
                                <Col xs="8">
                                    <DateTime
                                        onChange={(val: any) => {
                                            qualification["Partner Date of Birth"] = Moment(val).format("L")
                                            props.setFormData(qualification)
                                        }}
                                        timeFormat={false}
                                        value={Moment(qualification["Partner Date of Birth"])}
                                        closeOnSelect={true}
                                        inputProps={{ placeholder: "Date" }} />
                                </Col>
                            </FormGroup>
                            <FormGroup className="row">
                                <Label htmlFor="staticEmail" xs="4"> Citizen Status</Label>
                                <Col xs="8">
                                    <FormGroup check className="form-check-radio" >
                                        <Label check>
                                            <Input
                                                defaultValue="Australian "
                                                name="PartnerCitizenStatus"
                                                type="radio"
                                                onChange={(x) => {
                                                    qualification["Partner Citizen Status"] = 'Australian'
                                                    props.setFormData(qualification)

                                                }}
                                                checked={qualification["Partner Citizen Status"] === 'Australian'}
                                            ></Input>
                            Australian  <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                    <FormGroup check className="form-check-radio" >
                                        <Label check>
                                            <Input
                                                defaultValue="Visa"
                                                name="PartnerCitizenStatus"
                                                type="radio"
                                                onChange={(x) => {
                                                    qualification["Partner Citizen Status"] = 'Visa'
                                                    props.setFormData(qualification)
                                                }}
                                                checked={qualification["Partner Citizen Status"] === 'Visa'}
                                            ></Input>
                            Visa <span className="form-check-sign"></span>
                                        </Label>
                                    </FormGroup>
                                    <input
                                        className="form-control-plaintext"
                                        type="text"
                                        placeholder="Type of Visa"
                                        style={{ display: qualification["Partner Citizen Status"] === "Visa" ? '' : 'none' }}
                                        value={qualification["Partner Type of Visa"]}
                                        onChange={(evt) => {
                                            const val = evt.target.value
                                            qualification["Partner Type of Visa"] = val
                                            props.setFormData(qualification)
                                        }}
                                    ></input>
                                </Col>
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup check className="form-check-radio" >
                        <Label check>
                            <Input
                                defaultValue="Single"
                                name="MaritalStatus"
                                type="radio"
                                onChange={(x) => {
                                    qualification["Marital Status"] = 'Single'
                                    props.setFormData(qualification)

                                }}
                                checked={qualification["Marital Status"] === 'Single'}
                            ></Input>
                            Single <span className="form-check-sign"></span>
                        </Label>
                    </FormGroup>
                </Col>
            </FormGroup>
        </Container>
    )
}