import React from 'react'
import { Container, FormGroup, Label, Col  } from 'reactstrap'
import Switch from 'react-bootstrap-switch'
import { qualificationType } from '../shared/data-leads'
export type CreditHistoryProps = {
    formData: qualificationType
    setFormData: (formdata: qualificationType) => any
}
export function CreditHistory(props: CreditHistoryProps) {
    const qualification = props.formData
    return (
        <Container>
            <FormGroup className="row">
                <Label htmlFor="staticEmail" xs="10"> Have you ever declared bankruptcy?</Label>
                <Col xs="2">
                    <Switch
                        onChange={(x) => {
                            qualification["Have you ever declared bankruptcy"] = x.value() ? "YES" : 'NO'
                            props.setFormData(qualification)
                        }
                        }
                        value={qualification["Have you ever declared bankruptcy"] === 'YES' ? true : false} onText={"YES"} offText={"NO"} defaultValue={true} onColor="warning" />
                </Col>
            </FormGroup>
            
            
        </Container>
    )
}

/*
<div style={{ display: qualification["Have you Credit History"] === 'YES' ? '' : 'none' }}>
                <FormGroup className="row">
                    <Label htmlFor="staticEmail" xs="10"> Have you every been bankrupt or are you aware of any poor conduct on your credit file including defaults, writs or court actions </Label>
                    <Col xs="2">
                        <Switch
                            onChange={(x) => {
                                qualification["Have you every been bankrupt"] = x.value() ? "YES" : 'NO'
                                props.setFormData(qualification)
                            }
                            }
                            value={qualification["Have you every been bankrupt"] === 'YES' ? true : false} defaultValue={false} onText={"YES"} offText={"NO"} onColor="warning" />
                    </Col>
                </FormGroup>
                <Row style={{ display: qualification["Have you every been bankrupt"] === 'YES' ? '' : 'none' }}>
                    <Col xs={1}></Col>
                    <Col >
                        <FormGroup className="row">
                            <Label htmlFor="staticEmail" xs="8"> Are these matters still outstanding </Label>
                            <Col xs="4">
                                <input
                                    className="form-control-plaintext"
                                    type="text"
                                    placeholder="amount"
                                    onChange={(x) => {
                                        const val = x.target.value
                                        qualification["Are these matters still outstanding"] = val
                                        props.setFormData(qualification)
                                    }}
                                    value={qualification["Are these matters still outstanding"]}
                                ></input>
                            </Col>
                        </FormGroup>
                        <FormGroup className="row">
                            <Label htmlFor="staticEmail" xs="8"> When were they paid </Label>
                            <Col xs="4">
                                <input
                                    className="form-control-plaintext"
                                    type="text"
                                    placeholder="amount"
                                    onChange={(x) => {
                                        const val = x.target.value
                                        qualification["When were they paid"] = val
                                        props.setFormData(qualification)
                                    }}
                                    value={qualification["When were they paid"]}
                                ></input>
                            </Col>
                        </FormGroup>
                    </Col>
                </Row>
                <span> Are you currently in arears on any of the following; </span>
                <FormGroup check>
                    <Label check>
                        <Input
                            onChange={() => {
                                if (qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Your utility bills or property rates") > -1) {
                                    const idx = qualification["Are you currently in arears on any of the following"].indexOf("Your utility bills or property rates")
                                    qualification["Are you currently in arears on any of the following"].splice(idx, 1)
                                } else {
                                    if (!qualification["Are you currently in arears on any of the following"]) {
                                        qualification["Are you currently in arears on any of the following"] = []
                                    }
                                    qualification["Are you currently in arears on any of the following"].push("Your utility bills or property rates")
                                }
                                props.setFormData(qualification)

                            }}
                            checked={qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Your utility bills or property rates") > -1} type="checkbox"></Input>
                    Your utility bills or property rates
                    <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input onChange={() => {
                                if (qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Home loan") > -1) {
                                    const idx = qualification["Are you currently in arears on any of the following"].indexOf("Home loan")
                                    qualification["Are you currently in arears on any of the following"].splice(idx, 1)
                                } else {
                                    if (!qualification["Are you currently in arears on any of the following"]) {
                                        qualification["Are you currently in arears on any of the following"] = []
                                    }
                                    qualification["Are you currently in arears on any of the following"].push("Home loan")
                                }
                                props.setFormData(qualification)

                            }}
                            checked={qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Home loan") > -1} type="checkbox"></Input>
                    Home loan
                    <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input onChange={() => {
                                if (qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Personal loan") > -1) {
                                    const idx = qualification["Are you currently in arears on any of the following"].indexOf("Personal loan")
                                    qualification["Are you currently in arears on any of the following"].splice(idx, 1)
                                } else {
                                    if (!qualification["Are you currently in arears on any of the following"]) {
                                        qualification["Are you currently in arears on any of the following"] = []
                                    }
                                    qualification["Are you currently in arears on any of the following"].push("Personal loan")
                                }
                                props.setFormData(qualification)

                            }}
                            checked={qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Personal loan") > -1} type="checkbox"></Input>
                    Personal loan
                    <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                    </Label>
                </FormGroup>
                <FormGroup check>
                    <Label check>
                        <Input onChange={() => {
                                if (qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Credit cards") > -1) {
                                    const idx = qualification["Are you currently in arears on any of the following"].indexOf("Credit cards")
                                    qualification["Are you currently in arears on any of the following"].splice(idx, 1)
                                } else {
                                    if (!qualification["Are you currently in arears on any of the following"]) {
                                        qualification["Are you currently in arears on any of the following"] = []
                                    }
                                    qualification["Are you currently in arears on any of the following"].push("Credit cards")
                                }
                                props.setFormData(qualification)

                            }}
                            checked={qualification["Are you currently in arears on any of the following"] && qualification["Are you currently in arears on any of the following"].indexOf("Credit cards") > -1} type="checkbox"></Input>
                    Credit cards
                    <span className="form-check-sign">
                            <span className="check"></span>
                        </span>
                    </Label>
                </FormGroup>
                <FormGroup className="row">
                    <Label htmlFor="staticEmail" xs="10"> Do you regularly or have you recently dishonoured Payments or draw your every day accounts into a negative balance </Label>
                    <Col xs="2">
                        <Switch onChange={(x) => {
                            qualification["Do you regularly or have you recently dishonoured Payments or draw your every day accounts into a negative balance"] = x.value() ? "YES" : 'NO'
                            props.setFormData(qualification)
                        }
                        }
                        value={qualification["Do you regularly or have you recently dishonoured Payments or draw your every day accounts into a negative balance"] === 'YES' ? true : false}onText={"YES"} offText={"NO"} onColor="warning" />
                    </Col>
                </FormGroup>
                <FormGroup className="row">
                    <Label htmlFor="staticEmail" xs="10"> Do you regularly or have you recently been over the limit on any of your account </Label>
                    <Col xs="2">
                        <Switch onChange={(x) => {
                            qualification["Do you regularly or have you recently been over the limit on any of your account"] = x.value() ? "YES" : 'NO'
                            props.setFormData(qualification)
                        }
                        }
                        value={qualification["Do you regularly or have you recently been over the limit on any of your account"] === 'YES' ? true : false} onText={"YES"} offText={"NO"} onColor="warning" />
                    </Col>
                </FormGroup>
            </div>
*/