import React from 'react'
import { qualificationType } from '../shared/data-leads'
import { Container } from 'reactstrap'
export type ThankyouProps = {
    formData: qualificationType
}
export function Thankyou(props: ThankyouProps) {
    return (
        <Container>
            <p>Thank you for submitting our survey. One of the AAPFG (Australian Property & Finance Group) team will be in touch to set up an appointment, where we'll get to know you and your financial situation and discuss your lending needs.</p>
            <p>For further information, visit us on:</p>
            <p>W. <a href="https://apfg.com.au/">https://apfg.com.au/</a></p>
            <p>E. <a href="mailto:info@apfg.com.au">info@apfg.com.au</a></p>
        </Container>
    )
}