import { qualificationType } from "../shared/data-leads"
import Moment from 'moment'

export const persinalDetailFunction = (leads: qualificationType) => {
    let error = 0
    let errorMessage = ""

    if (leads.Name && leads.Name.length > 0) {
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Enter Your Name"
        error = 1
    }
    if (leads["Date of Birth"] && leads["Date of Birth"].length > 0) {
        error = 0
        const sekarang = Moment(new Date())
        const selisih: number = Moment.duration(sekarang.diff(leads["Date of Birth"])).asYears()
        if (selisih < 17) {
            if (errorMessage.length > 0) {
                errorMessage += ", "
            }
            errorMessage += "Your Ages is not Alowed"
            error = 1
        }
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Enter Date of Birth"
        error = 1
    }
    if (leads.Address && leads.Address.length > 0) {
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Enter Your Address"
        error = 1
    }
    if (leads["Residential Status"] && leads["Residential Status"].length > 0) {
        error = 0
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Choose Your Residential Status"
        error = 1
    }
    if (leads["Citizen Status"] && leads["Citizen Status"].length > 0) {
        console.log("Citizen Status", leads["Citizen Status"] === 'Visa')
        if(leads["Citizen Status"] === "Visa") {
            console.log("Type of Visa", leads["Type of Visa"] && leads["Type of Visa"].length > 0)
            if(leads["Type of Visa"] && leads["Type of Visa"].length > 0) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Your Type of Visa"
                error = 1
            }
        } else {
            error = 0
        }
    } else {
        if (errorMessage.length > 0) {
            errorMessage += ", "
        }
        errorMessage += "Please Choose Your Citizen Status"
        error = 1
    }
    if (error === 0) {
        if (leads["Marital Status"] && leads["Marital Status"].length > 0) {
            if(leads["Marital Status"]  === "Married/DeFacto") {
                if (leads["Partner Full Name"] && leads["Partner Full Name"].length > 0) {
                    error = 0
                } else {
                    if (errorMessage.length > 0) {
                        errorMessage += ", "
                    }
                    errorMessage += "Please Enter Your Partner Name"
                    error = 1
                }
                if (leads["Partner Date of Birth"] && leads["Partner Date of Birth"].length > 0) {
                    error = 0
                    const sekarang = Moment(new Date())
                    const selisih: number = Moment.duration(sekarang.diff(leads["Partner Date of Birth"])).asYears()
                    if (selisih < 17) {
                        if (errorMessage.length > 0) {
                            errorMessage += ", "
                        }
                        errorMessage += "Your Partner Ages is not Alowed"
                        error = 1
                    }
                } else {
                    if (errorMessage.length > 0) {
                        errorMessage += ", "
                    }
                    errorMessage += "Please Enter Your Partner Date of Birth"
                    error = 1
                }
                if (leads["Partner Citizen Status"] && leads["Partner Citizen Status"].length > 0) {
                    if(leads["Partner Citizen Status"] === 'Visa') {
                        if(leads["Partner Type of Visa"] && leads["Partner Type of Visa"].length > 0) {
                            error = 0
                        } else {
                            if (errorMessage.length > 0) {
                                errorMessage += ", "
                            }
                            errorMessage += "Please Enter Your partner Type of Visa"
                            error = 1
            
                        }
                    } else {
                        error = 0
                    }
                } else {
                    if (errorMessage.length > 0) {
                        errorMessage += ", "
                    }
                    errorMessage += "Please Choose Your Parner Citizen Status"
                    error = 1
                }
    
            } else {
                error = 0
            }
        } else {
            if (errorMessage.length > 0) {
                errorMessage += ", "
            }
            errorMessage += "Please Choose Your Marital Status"
            error = 1
        }
    }
   
    return {
        error: error,
        errorMessage: errorMessage
    }

}