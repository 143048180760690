import { qualificationType } from "../shared/data-leads"

export const FunctionOtherIncome = (leads: qualificationType) => {
    let error = 0
    let errorMessage = ""

    if(leads["Other Income"] && leads["Other Income"].length > 0) {
        if(leads["Other Income"] && leads["Other Income"].indexOf("Rental Income") > -1  ) {
            if(leads["Rental Income"] && leads["Rental Income"].length > 0) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Amount"
                error = 1
            }
        }
        if(leads["Other Income"] && leads["Other Income"].indexOf("Centrelink") > -1  ) {
            if(leads["Centrelink"] && leads["Centrelink"].Type  && leads["Centrelink"].Type.length > 0 ) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Centrelink Type"
                error = 1
            }
            if(leads["Centrelink"] && leads["Centrelink"].Amount  && leads["Centrelink"].Amount.length > 0 ) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Centrelink Amount"
                error = 1
            }
        }
        if(leads["Other Income"] && leads["Other Income"].indexOf("Second Job") > -1  ) {
            
        }
        if(leads["Other Income"] && leads["Other Income"].indexOf("Other") > -1  ) {
            if(leads["Other"] && leads["Other"].Type  && leads["Other"].Type.length > 0 ) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Centrelink Type"
                error = 1
            }
            if(leads["Other"] && leads["Other"].Amount  && leads["Other"].Amount.length > 0 ) {
                error = 0
            } else {
                if (errorMessage.length > 0) {
                    errorMessage += ", "
                }
                errorMessage += "Please Enter Centrelink Amount"
                error = 1
            }
        }
    }
    
    
    return {
        error: error,
        errorMessage: errorMessage
    }
}